import React, { useState } from 'react'
import "./Header.css"
import logo from "../../assets/logo.png"
import bars from "../../assets/bars.png"
import { Link } from "react-scroll"


const Header = () => {
    const mobile = window.innerWidth <= 768 ? true : false;

    const [menuOpened, setMenuOpened] = useState(false)
    return (
        <>
            <div className='header'>
                <img src={logo} className='logo' alt="logo image" />
                {menuOpened === false && mobile === true ? (<div><img src={bars} alt="" className="bars" onClick={() => {setMenuOpened(!menuOpened)}} /></div>) : (<ul className='header-menu'>
                    <li > <Link onClick={() => {setMenuOpened(false)}} activeClass='active' to='home' smooth="true" spy="true">Home</Link></li>
                    <li > <Link onClick={() => {setMenuOpened(false)}} to='programs' smooth="true" spy="true">Programs</Link></li>
                    <li > <Link onClick={() => {setMenuOpened(false)}} to='reasons' smooth="true" spy="true">Why us</Link></li>
                    <li > <Link onClick={() => {setMenuOpened(false)}} to='plans' smooth="true" spy="true">Plans</Link></li>
                    <li> <Link onClick={() => {setMenuOpened(false)}} to='testimonials' smooth="true" spy="true">Testimonials</Link></li>
                </ul>)
                }

            </div>
        </>
    )
}

export default Header
